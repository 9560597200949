import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { Campaigns } from "@warrenio/api-spec/spec.oats.gen";
import { Suspense, useState } from "react";
import { Badge } from "../../components/Badge.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { WSearchField } from "../../components/forms/WSearchField.tsx";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { AddCampaignModal } from "./AddCampaignModal.tsx";
import { campaignsQueryAtom, campaignTypeMap, deleteCampaignMutation } from "./campaignsQuery.ts";

function CampaignsViewCustom() {
    const deleteMutation = useStandardMutation(deleteCampaignMutation);
    const campaigns = useSuspenseQueryAtom(campaignsQueryAtom);
    const [queryString, setQueryString] = useState("");

    async function onDelete(item: Campaigns) {
        await deleteMutation.mutateAsync({
            id: String(item.id),
        });
    }

    return (
        <>
            <AdminTitle title="Campaigns">
                <AddCampaignModal button={<WModalButton label="New" color="primary" size="bar" variant="basic" />} />
            </AdminTitle>

            <WTable adminTable search={<WSearchField value={queryString} onChange={setQueryString} />}>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Url</th>
                        <th>Description</th>
                        <th className="text-right">Credit Amount</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th className="text-right">
                            <div className="flex justify-end">
                                Used Count
                                <InfoTooltip
                                    className="ml-1"
                                    text="The count here reflects how many times the campaign has been successfully used. Results in billing account table may not always reflect as accurate usage statistics. Read more from documentation."
                                />
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {campaigns.map((item) => (
                        <tr key={item.id}>
                            <td>
                                <ClipBoardTooltip text={item.campaign_code}>{item.campaign_code}</ClipBoardTooltip>
                            </td>
                            <td>
                                {item.campaign_type === "new_users" ? (
                                    <ClipBoardTooltip text={window.location.origin + "/referral/" + item.campaign_code}>
                                        <span className="text-accept">
                                            {window.location.origin + "/referral/" + item.campaign_code}
                                        </span>
                                    </ClipBoardTooltip>
                                ) : (
                                    "-"
                                )}
                            </td>
                            <td>{item.campaign_description}</td>
                            <td className="text-right">
                                <CurrencyPromo value={item.credit_amount} />
                            </td>
                            <td>{campaignTypeMap[item.campaign_type]}</td>
                            <td>
                                <Badge color={item.is_active ? "success" : "error"}>
                                    {item.is_active ? "Active" : "Inactive"}
                                </Badge>
                            </td>
                            <td>
                                <LongDate date={apiUnixToDate(item.created_at)} />
                            </td>
                            <td className="text-right">{item.used_count}</td>
                            <td className="text-right">
                                <div className="flex gap-0.5em justify-end">
                                    <WModal button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}>
                                        <WModalContent title="Edit Campaign" label="Edit" modalAction={todoAction}>
                                            <TODO />
                                        </WModalContent>
                                    </WModal>

                                    <DeleteModal
                                        title="Delete Campaign"
                                        inTable
                                        modalAction={async () => await onDelete(item)}
                                    >
                                        <p>
                                            Are you sure you want to delete campaign "<b>{item.campaign_description}</b>
                                            " with code "<b>{item.campaign_code}</b>"?
                                        </p>
                                    </DeleteModal>
                                </div>
                            </td>
                        </tr>
                    ))}
                </WTableBody>
            </WTable>

            <div className="p-2">
                <AddCampaignModal
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Campaign"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}

export function CampaignsView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <CampaignsViewCustom />
        </Suspense>
    );
}
