import type { QueryClient } from "@tanstack/query-core";
import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { Campaigns, CampaignsCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { raiseRequestToast, type Toast } from "../../modules/notifications/toast.tsx";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";

export type Response = Campaigns[];

export const campaignTypeMap = { new_users: "New Users", all_users: "All Users" };

export const queryKey: QueryKey = ["campaigns"];

const toastOptions: Partial<Toast> = { icon: "" };

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/payment/admin/campaign/list", { signal }));
        },
    });
}

export const campaignsQueryAtom = atomFromStandardQueryOptions(getQuery);

//#region Mutations
export function campaignCreateMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["payment/admin/campaign"],
        async mutationFn({ body }: { body: CampaignsCreateBody }) {
            return getResponseData(
                await api.POST("/payment/admin/campaign", {
                    ...jsonEncodedBody,
                    body,
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Campaign created",
                error: "Error creating campaign",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function deleteCampaignMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["/payment/admin/campaign/"],
        async mutationFn({ id }: { id: string }) {
            return getResponseData(
                await apiClient.DELETE("/payment/admin/campaign/{id}", {
                    params: { path: { id } },
                }),
            );
        },

        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Campaign deleted",
                error: "Failed to delete Campaign",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

//#endregion Mutations
